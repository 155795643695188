import React, { createContext, useEffect, useState } from 'react'
import { useDebouncedCallback } from '~/components/hooks/useDebouncedCallback'
import { ensureNumber, isNonNullish, type Optional } from '~/types/strict-null-helpers'
import mediaQueries from '~/lib/client-only/media-queries'

export type LayoutMeasurementContextProps = {
	consentBannerHeight: Optional<number>
	setConsentBannerElement: (element: Optional<HTMLElement>) => void
	measureConsentBannerHeight: () => void
	headerHeight: Optional<number>
	setHeaderElement: (element: Optional<HTMLElement>) => void
	setObserveTopMargin: (value: boolean) => void
	topMarginHeight: Optional<number>
	isMobile: boolean
	isDesktop: boolean
}

export const defaultLayoutMeasurementProvider = {
	consentBannerHeight: undefined,
	setConsentBannerElement: (_: Optional<HTMLElement>) => undefined,
	measureConsentBannerHeight: () => undefined,
	headerHeight: undefined,
	setHeaderElement: (_: Optional<HTMLElement>) => undefined,
	setObserveTopMargin: (_: boolean) => undefined,
	topMarginHeight: undefined,
	isMobile: false,
	isDesktop: false,
} as LayoutMeasurementContextProps

export const LayoutMeasurementContext = createContext(defaultLayoutMeasurementProvider)

/**
 * Make dynamically-sized element measurements available everywhere in .tsx and .scss
 */
export const LayoutMeasurementProvider = ({ children }: React.PropsWithChildren): React.ReactElement => {
	const [branchBannerHeight, setBranchBannerHeight] = useState<Optional<number>>(undefined)
	const [branchBannerWatchScroll, setBranchBannerWatchScroll] = useState<boolean>(false)
	const [consentBannerHeight, setConsentBannerHeight] = useState<Optional<number>>(undefined)
	const [consentBannerElement, setConsentBannerElement] = useState<Optional<HTMLElement>>(undefined)
	const [headerHeight, setHeaderHeight] = useState<Optional<number>>(undefined)
	const [headerElement, setHeaderElement] = useState<Optional<HTMLElement>>(undefined)
	const [observeTopMargin, setObserveTopMargin] = useState(false)
	const [topMarginHeight, setTopMarginHeight] = useState<Optional<number>>(undefined)
	const [isMobile, setIsMobile] = useState<boolean>(false)
	const [isDesktop, setIsDesktop] = useState<boolean>(false)

	const measureHeaderHeight = () => {
		if (headerElement) {
			setHeaderHeight(headerElement.getBoundingClientRect().height)
		}
	}

	const measureConsentBannerHeight = () => {
		setConsentBannerHeight(ensureNumber(consentBannerElement?.offsetHeight))
	}

	const measureTopMargin = useDebouncedCallback(() => {
		setTopMarginHeight(
			isNonNullish(branchBannerHeight) && window.scrollY < branchBannerHeight ? branchBannerHeight - window.scrollY : 0,
		)
	}, 100)

	const measureHeights = useDebouncedCallback(() => {
		measureHeaderHeight()
		measureConsentBannerHeight()
	}, 100)

	useEffect(() => {
		setConsentBannerHeight(ensureNumber(consentBannerElement?.offsetHeight))
	}, [consentBannerElement])

	// Track height based on position type
	useEffect(() => {
		if (!isNonNullish(branchBannerHeight)) return undefined
		if (!branchBannerWatchScroll) {
			setTopMarginHeight(branchBannerHeight)
			return undefined
		}
		window.addEventListener('scroll', measureTopMargin)
		measureTopMargin()
		return () => {
			return window.removeEventListener('scroll', measureTopMargin)
		}
	}, [branchBannerWatchScroll, branchBannerHeight, measureTopMargin])

	// Match Media isMobile and isDesktop listeners ************
	useEffect(() => {
		function handleMobileMediaChange(media: MediaQueryListEvent) {
			setIsMobile(media.matches)
		}
		function handleDesktopMediaChange(media: MediaQueryListEvent) {
			setIsDesktop(media.matches)
		}
		const mobileMediaQuery = window?.matchMedia(mediaQueries.mobile)
		const desktopMediaQuery = window?.matchMedia(mediaQueries.lgDesktopUp)

		setIsMobile(mobileMediaQuery.matches)
		setIsDesktop(desktopMediaQuery.matches)
		mobileMediaQuery.addEventListener('change', handleMobileMediaChange)
		desktopMediaQuery.addEventListener('change', handleDesktopMediaChange)
		return () => {
			mobileMediaQuery.removeEventListener('change', handleMobileMediaChange)
			desktopMediaQuery.removeEventListener('change', handleDesktopMediaChange)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Resize listener
	useEffect(() => {
		window.addEventListener('resize', measureHeights)
		measureHeights()
		return () => {
			return window.removeEventListener('resize', measureHeights)
		}
	}, [measureHeights])

	// Update css vars when measured values change
	useEffect(() => {
		if (isNonNullish(topMarginHeight)) {
			document.documentElement.style.setProperty('--safe-margin-top', `${topMarginHeight}px`)
		}
		if (isNonNullish(consentBannerHeight)) {
			document.documentElement.style.setProperty('--safe-margin-bottom', `${consentBannerHeight}px`)
		}
		if (isNonNullish(headerHeight)) {
			document.documentElement.style.setProperty('--header-height', `${headerHeight}px`)
		}
	}, [topMarginHeight, consentBannerHeight, headerHeight, branchBannerWatchScroll])

	// Find the height of the app banner, if present
	useEffect(() => {
		if (!observeTopMargin) return undefined

		const bodyElement = document.body

		const observer = new MutationObserver((mutationList) => {
			mutationList.forEach((mutation) => {
				if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
					const bodyClasses = (mutation.target as HTMLBodyElement).getAttribute('class')?.split(' ')
					const hasBanner = (bodyClasses || []).some((s) => s.includes('branch-banner-is-active'))

					if (hasBanner) {
						// Banner has been added
						const bannerElement = document.getElementById('branch-banner-iframe')
						if (bannerElement) {
							setBranchBannerHeight(bannerElement.offsetHeight)
							// Can either be position:fixed or position:absolute
							const bannerPosition = window.getComputedStyle(bannerElement).getPropertyValue('position')
							setBranchBannerWatchScroll(bannerPosition === 'absolute')
							bodyElement.setAttribute('data-branch-banner', bannerPosition)
						}
					} else if (!hasBanner && isNonNullish(branchBannerHeight)) {
						// Banner has been removed
						setBranchBannerHeight(0)
						setBranchBannerWatchScroll(false)
					}
				}
				// Position:fixed from useDeactivateBodyScroll conflicts with banner in absolute position
				if (
					branchBannerWatchScroll &&
					mutation.type === 'attributes' &&
					mutation.attributeName === 'style' &&
					window.getComputedStyle(bodyElement).getPropertyValue('position') === 'fixed'
				) {
					bodyElement.style.removeProperty('position')
				}
			})
		})

		observer.observe(bodyElement, { childList: true, attributeFilter: ['class', 'style'] })

		return () => {
			observer.disconnect()
		}
	}, [observeTopMargin, branchBannerHeight, branchBannerWatchScroll])

	const contextValue = {
		consentBannerHeight,
		setConsentBannerElement,
		measureConsentBannerHeight,
		headerHeight,
		setHeaderElement,
		setObserveTopMargin,
		topMarginHeight,
		isMobile,
		isDesktop,
	}

	return <LayoutMeasurementContext.Provider value={contextValue}>{children}</LayoutMeasurementContext.Provider>
}
