'use client'

// TODO: It would be worth making this an RSC if possible. (Should be doable if we pass `locale` and use event delegation.)
import Link from 'next/link'
import { forwardRef } from 'react'
import { useLocale } from '~/components/hooks/useLocale'
import { getSupportedLocales } from '~/lib/i18n/locale'
import { isExternalLink } from '~/lib/utils'
import { LINK_CLICK_EVENT } from '~/lib/client-only/events'
import { getUnsavedChangesStorage, showUnsavedDialog } from '~/components/hooks/useUnsavedChanges'

export type LocaleLinkOnClick = (
	event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
) => void | boolean | Promise<boolean | void>

export interface LocaleLinkProps extends React.ComponentProps<typeof Link> {
	href: string
	onClick?: LocaleLinkOnClick
}

interface CustomClickEvent {
	url: string
	target: string
	keyboardAction: boolean
}

/**
 * In the case of a relative URL, this will prepend the current locale to the URL.
 * * `/foo -> /en-us/foo`
 * * `https://www.google.com -> https://www.google.com [DO NOTHING]`
 * * `/en-us/foo -> /en-us/foo [DO NOTHING]`
 *
 * @param param0
 * @returns
 */
export const LocaleLink = forwardRef(function LocaleLink(
	{ href, onClick, prefetch = false, ...attrs }: LocaleLinkProps,
	ref: React.Ref<HTMLAnchorElement>,
) {
	const locale = useLocale()
	let url = href

	const handleClickEvent = async (e) => {
		// check if we have any unsaved changes before proceeding
		if (getUnsavedChangesStorage().hasUnsavedChanges) {
			e.preventDefault()
			await onClick?.(e)
			showUnsavedDialog(href)
			return undefined
		}
		const customEventDetails = {
			url: e.currentTarget.getAttribute('href'),
			target: e.currentTarget.getAttribute('target'),
			keyboardAction: e.ctrlKey || e.shiftKey || e.metaKey,
		}
		const onClickResult = await onClick?.(e)
		// we use result of onClick method as flag for avoiding redirection to new page
		if (onClickResult !== false) {
			/**
			 * Will dispatch the user interaction event to a custom event. This event, linkClick, is listened
			 * to by the layout-level loading component as an interstitial loading visual for usability purposes.
			 */
			window.dispatchEvent(
				new CustomEvent<CustomClickEvent>(LINK_CLICK_EVENT, {
					detail: customEventDetails,
				}),
			)
		}
		return onClickResult
	}

	if (!isExternalLink(url)) {
		const urlIncludesLocale = getSupportedLocales().filter((l) => href?.includes(l))
		const base = urlIncludesLocale.length > 0 ? href.split(urlIncludesLocale[0])[1] : href
		url = `/${locale}${base}`
	}

	return <Link ref={ref} href={url} prefetch={prefetch} onClick={handleClickEvent} {...attrs} />
})
