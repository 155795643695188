interface CustomRoute {
	route: string
	text: string
	filter?: string
	external: boolean
}

export const accountNavData = [
	{ route: '/account', text: 'my-dashboard' },
	{ route: '/my-order-history', text: 'order-history' },
	{ route: '/my-account', text: 'profile' },
	{ route: '/preferred-sizes', text: 'preferred-sizes', filter: 'preferredSizes' },
	{ route: '/payment-methods', text: 'payment-methods' },
	{ route: '/addresses', text: 'addresses' },
	{ route: '/saved-items', text: 'saved-items' },
	{ route: '/my-rewards-locker', text: 'ua-rewards', filter: 'loyaltyStatus' },
	{ route: '/t/rewards.html', text: 'rewards-join', filter: 'registeredNoLoyalty', external: true },
] as CustomRoute[]
