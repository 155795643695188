'use client'

import { sessionStorageGet, sessionStorageSet } from '~/lib/client-only/storage'
import { useEffect, useMemo, useState } from 'react'

const UNSAVED_CHANGES_STATE = 'unsaved-changes-state'

interface UnsavedChangesState {
	showDialog: boolean
	proceedLinkHref: string
	hasUnsavedChanges: boolean
}

export const getUnsavedChangesStorage = (): UnsavedChangesState => {
	const unsavedChangesStorage = sessionStorageGet(UNSAVED_CHANGES_STATE)
	return JSON.parse(unsavedChangesStorage || '{}')
}

export const showUnsavedDialog = (href: string) => {
	const unsavedChangesItem = {
		...getUnsavedChangesStorage(),
		showDialog: true,
		proceedLinkHref: href,
	}
	sessionStorageSet(UNSAVED_CHANGES_STATE, JSON.stringify(unsavedChangesItem))
	window.dispatchEvent(new Event('storage'))
}

const defaultUnsavedChanges = {
	showDialog: false,
	proceedLinkHref: '',
	hasUnsavedChanges: false,
}

// use this hook once on the page that you need to track your changes on
export function useUnsavedChanges() {
	const [showDialog, setShowDialog] = useState<boolean>(Boolean(getUnsavedChangesStorage().showDialog))
	const [proceedLinkHref, setProceedLinkHref] = useState<string>(getUnsavedChangesStorage().proceedLinkHref || '')
	const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(
		Boolean(getUnsavedChangesStorage().hasUnsavedChanges),
	)

	const clearUnsavedChanges = () => {
		sessionStorageSet(UNSAVED_CHANGES_STATE, JSON.stringify({ ...defaultUnsavedChanges }))
		setShowDialog(false)
	}

	// track session storage changes
	useEffect(() => {
		clearUnsavedChanges()
		const mapUnsavedStorage = () => {
			const unsavedChangesItem: UnsavedChangesState = getUnsavedChangesStorage()
			setShowDialog(Boolean(unsavedChangesItem.showDialog))
			setProceedLinkHref(unsavedChangesItem.proceedLinkHref || '')
			setHasUnsavedChanges(Boolean(unsavedChangesItem.hasUnsavedChanges))
		}

		window.addEventListener('storage', mapUnsavedStorage)
		return () => {
			window.removeEventListener('storage', mapUnsavedStorage)
			clearUnsavedChanges()
		}
	}, [])

	// show system dialog on reload
	useEffect(() => {
		const handleBeforeUnload = (e: BeforeUnloadEvent) => {
			if (!!proceedLinkHref || hasUnsavedChanges) {
				e.preventDefault()
				e.returnValue = ''
			}
		}

		window.addEventListener('beforeunload', handleBeforeUnload)
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload)
		}
	}, [proceedLinkHref, hasUnsavedChanges])

	return useMemo(
		() => ({
			showDialog,
			closeDialog: () => {
				const unsavedChangesItem = {
					...getUnsavedChangesStorage(),
					showDialog: false,
				}
				setShowDialog(false)
				sessionStorageSet(UNSAVED_CHANGES_STATE, JSON.stringify(unsavedChangesItem))
			},
			proceedLinkHref,
			setUnsavedChanges: (hasChanges: boolean) => {
				const unsavedChangesItem = {
					...getUnsavedChangesStorage(),
					hasUnsavedChanges: hasChanges,
				}
				sessionStorageSet(UNSAVED_CHANGES_STATE, JSON.stringify(unsavedChangesItem))
				window.dispatchEvent(new Event('storage'))
			},
			clearUnsavedChanges,
		}),
		[showDialog, proceedLinkHref],
	)
}
