import variables from '~/styles/variables.module.scss'

const mediaQueries = {
	mobile: `(max-width: ${variables.mobileBreak})`, // NOTE: Range is intentionally 1 pixel larger than production
	/** Desktops 768px and up */
	desktopUp: `(min-width: ${variables.mobileMenuQueryMin})`,
	/** Tablets 767px and down */
	tabletDown: `(max-width: ${variables.mobileMenuQuerySm})`,
	/** Desktops 768px and up */
	smDesktop: `(min-width: ${variables.smDesktop})`,
	/** Desktops 1024px and up */
	lgDesktopUp: `(min-width: ${variables.lgDesktop})`,
	/** Desktops 1023px and down */
	lgDesktopDown: `(max-width: ${variables.lgTablet})`,
} as const

export type DeviceType = 'mobile' | 'desktop' | 'tablet'

export function getMediaQueryFromDeviceType(deviceType: DeviceType) {
	switch (deviceType) {
		case 'mobile':
			// 767px and down
			return `(max-width: ${variables.mobileMenuQuerySm})`
		case 'tablet':
			// 768px to 1023px
			return `(min-width: ${variables.smDesktop}) and (max-width: ${variables.lgTablet})`
		case 'desktop':
			// 1024px and up
			return `(min-width: ${variables.lgDesktop})`
		default:
			return ''
	}
}

export default mediaQueries
