import React, { useMemo } from 'react'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import styles from './Button.module.scss'
import type { AsyncStatus } from '~/types/global'
import { LoadingDots } from '~/components/primitives/LoadingDots/LoadingDots'
import clsx from 'clsx'

export type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'text' | 'hug'
export interface ButtonVariantProps {
	/** Defines the variant of the button, by default the button variant would be primitive, but the explicit prop variant types (`secondary`,`tertiary`,`tertiary`,`text`) would override this. */
	variant?: ButtonVariant
	/** Secondary button variation */
	secondary?: boolean
	/** Tertiary button variation */
	tertiary?: boolean
	/** Text button variation */
	text?: boolean
	/** Hug button variation */
	hug?: boolean
}
export interface ButtonProps extends React.ComponentPropsWithoutRef<'button'>, ButtonVariantProps {
	/** The current loading status of the action that this button initiated. A status of `pending` or `resolved` automatically `disables` the button (overridable) */
	status?: AsyncStatus
	/** Button type prop that semantically describes the purpose of the button, defaulting to "button" */
	type?: Exclude<React.ComponentPropsWithoutRef<'button'>['type'], undefined>
	/** Implementing custom class on the root button element, but should avoid passing in the legacy btn-* class names */
	className?: string
}

export const getVariantState = ({
	secondary,
	tertiary,
	text,
	hug,
	variant = 'primary',
}: ButtonVariantProps): ButtonVariant => {
	if (secondary) return 'secondary'
	if (tertiary) return 'tertiary'
	if (text) return 'text'
	if (hug) return 'hug'
	return variant
}

/**
 * A design-system enforced button component that enforces the various states of the button.
 * 1. primary | secondary | tertiary | text | hug variants passed directly as props:
 * 2. Pending/Resolved states that will render loading icons.
 * 3. Native button element type handling button | submit | reset
 *
 * ```JSX
 * 	<Button>Click Me</Button> // will default to button type, primary viariant
 * 	<Button secondary>Click Me</Button> // shorthand variant prop notation
 * ```
 */
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(function Button(
	{
		status,
		type = 'button',
		variant = 'primary',
		secondary,
		tertiary,
		text,
		hug,
		children,
		className = '',
		...attrs
	}: ButtonProps,
	ref,
) {
	const formatMessage = useFormatMessage()
	const disabledStatus = ['pending', 'resolved']

	const LoadingButtonContent = () => (
		<>
			<LoadingDots shape="circle" />
			<span className="visually-hidden">{formatMessage('loading-ellipsis')}</span>
		</>
	)

	const variantState: ButtonVariant = useMemo(
		() =>
			getVariantState({
				variant,
				secondary,
				tertiary,
				text,
				hug,
			}),
		[hug, secondary, tertiary, text, variant],
	)

	const isPending = useMemo(() => status === 'pending', [status])

	/* eslint-disable react/button-has-type */
	return (
		<button
			className={clsx(styles.btn, styles[`btn__${variantState}`], className.trim())}
			ref={ref}
			type={type}
			disabled={disabledStatus.includes(status ?? '')}
			{...attrs}
			data-variant={variantState}
			data-status={status}
		>
			{isPending ? <LoadingButtonContent /> : children}
		</button>
	)
	/* eslint-disable react/button-has-type */
})

Button.displayName = 'Button'
export default React.memo(Button)
