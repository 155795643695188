export function storageSet(key: string, value: string | undefined, storage: Storage = window?.localStorage) {
	if (typeof window === 'undefined' || !storage) return
	if (value === undefined) {
		storage.removeItem(key)
		return
	}
	storage.setItem(key, value)
}

export function storageGet(key: string, storage: Storage = window?.localStorage): string | undefined {
	if (typeof window === 'undefined' || !storage) return undefined
	return storage.getItem(key) ?? undefined
}

export function sessionStorageSet(key: string, value: string | undefined) {
	if (typeof window === 'undefined') return undefined
	return storageSet(key, value, window?.sessionStorage)
}

export function sessionStorageGet(key: string): string | undefined {
	if (typeof window === 'undefined') return undefined
	return storageGet(key, window?.sessionStorage)
}
