import React from 'react'
import clsx from 'clsx'
import styles from '~/components/primitives/Button/Button.module.scss'
import type { IconProps } from '~/lib/icons'

interface IconButtonProps extends React.ComponentPropsWithoutRef<'button'> {
	/** Adding a class to the component */
	className?: string
	/** Similar to the values form the `button`, we can set the iconButton to be of type `submit` or `button`. */
	type?: React.ComponentPropsWithoutRef<'button'>['type']
	/** Accessibility hidden label adjacent to the icon, to describe it. */
	label: string
	children: React.ReactElement<IconProps>
}

/**
 * An `IconButton` component eniforces the styling around a IconProp type icon within our app's design system. Only
 * a functional component of type IconProps is allowed as the child, and only on element.
 *
 * The component will inherit any attributes available on a `HTMLButtonElement` type.
 */
export const IconButton = ({ className, children, label, type = 'button', ...attrs }: IconButtonProps) => {
	/* eslint-disable react/button-has-type */
	return (
		<button
			type={type}
			role="button"
			aria-label={label}
			className={clsx(styles.icon_button, className?.trim())}
			{...attrs}
			tabIndex={0}
		>
			{children}
		</button>
	)
}
