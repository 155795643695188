import type { IconProps } from '~/lib/icons'
import { getEnhancedIconProps } from '~/lib/icons'

function ArrowIcon(restProps: IconProps) {
	return (
		<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...getEnhancedIconProps(restProps)}>
			<path
				d="M5 2L10.9894 7.98939C10.9953 7.99525 10.9993 8.00073 10.9934 8.00659C10.7711 8.22886 5 14 5 14"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}

export default ArrowIcon
