import { useCallback, useMemo } from 'react'
import { useFeatureFlags } from '~/components/providers/CommerceConfigurationProvider/CommerceConfigurationProvider'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import { useLocale } from '~/components/hooks/useLocale'
import mobileNavStyles from '~/components/layouts/NavbarLayout/Navigation/MobileNav/MobileNav.module.scss'
import { accountNavData } from '~/components/layouts/NavbarLayout/Navigation/account-nav-data'
import { useSession } from '~/components/providers/UaSessionProvider/UaSessionProvider'
import { LocaleLink } from '~/components/primitives/LocaleLink/LocaleLink'
import ArrowIcon from '~/components/primitives/icons/ArrowIcon'
import { getPublicConfig } from '~/lib/client-server/config'
import styles from './MyAccountLinks.module.scss'
import { getUrlWithoutLocale } from '~/lib/i18n/urls'
import Button from '~/components/primitives/Button/Button'
import { IconButton } from '~/components/primitives/Button/IconButton'

interface MyAccountLinksProps {
	showLogout?: boolean
	showLoyalty?: boolean
	showNavArrows?: boolean
	inactiveForEqualRoutes?: boolean
	currentPath: string
	onClick?: () => void
}

/* -------------------- Utility Components -------------------- */
export function MyAccountLinks({
	showLogout = false,
	showLoyalty = true,
	showNavArrows = false,
	inactiveForEqualRoutes,
	currentPath,
	onClick,
}: MyAccountLinksProps) {
	const formatMessage = useFormatMessage()
	const rawPath = getUrlWithoutLocale(currentPath)
	const { user: customer, logout } = useSession()
	const featureFlags = useFeatureFlags()
	const locale = useLocale()

	// Filter the routes based on the loyalty status & preferred sizes config
	const filteredNavData = useMemo(() => {
		return accountNavData.filter(
			(route) =>
				!route.filter ||
				(route.filter === 'loyaltyStatus' && featureFlags?.isLoyaltyEnabled && customer?.isLoyalty && showLoyalty) ||
				(route.filter === 'registeredNoLoyalty' &&
					featureFlags?.isLoyaltyEnabled &&
					!featureFlags?.isLoyaltyPilotEnabled &&
					customer?.isRegistered &&
					!customer.isLoyalty) ||
				(route.filter === 'preferredSizes' && getPublicConfig().preferredsizes.locales.includes(locale)),
		)
	}, [customer, featureFlags, locale, showLoyalty])

	const getInertProp = useCallback(
		(route) => {
			if (inactiveForEqualRoutes) {
				return rawPath === route ? '' : undefined
			}
			return rawPath.startsWith(`${route}`) ? '' : undefined
		},
		[inactiveForEqualRoutes, rawPath],
	)

	const handleSignOut = async () => {
		logout()
		if (onClick) onClick()
	}
	return (
		// TODO: Consider adding an accessible label for this `nav` element
		<nav className={styles['my-account-links']}>
			<ul>
				{filteredNavData.map(({ route, text }) => (
					<li key={text}>
						<LocaleLink
							href={route}
							aria-current={rawPath.startsWith(`${route}`) ? 'page' : undefined}
							// @ts-expect-error -- This is a real property. See MDN
							inert={getInertProp(route)}
							onClick={onClick}
						>
							{formatMessage(text)}
							{showNavArrows && (
								<IconButton
									className={mobileNavStyles['mobile-forward-arrow']}
									label={formatMessage('select-category', {
										category: formatMessage(text).toString(),
									})}
								>
									<ArrowIcon size="MD" />
								</IconButton>
							)}
						</LocaleLink>
					</li>
				))}
				{showLogout && (
					<li className={styles.logout}>
						<Button type="button" secondary onClick={handleSignOut}>
							{formatMessage('logout')}
						</Button>
					</li>
				)}
			</ul>
		</nav>
	)
}
